<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="seachStatus" status="my-orange" @click="showSearch(false)">隐藏查询</vxe-button>
        <vxe-button
          v-if="!seachStatus"
          status="my-orange"
          icon="fa fa-search"
          @click="showSearch(true)"
        >查询</vxe-button>
        <vxe-button v-permission="'AddSumCenter'" status="my-orange" icon="fa fa-plus" @click="goPage('add')">添加</vxe-button>
        <vxe-button v-permission="'SumCenterExportButton'" status="my-orange" icon="fa fa-plus" @click="exportData()">导出</vxe-button>
      </template>

    </vxe-toolbar>
    <!-- <el-header>Header</el-header> -->
    <div v-if="seachStatus" class="search">
      <vxe-form
        title-align="right"
        title-width="auto"
        title-colon
        :data="searchData"
        @submit="searchEvent"
        @reset="searchReset"
      >
        <vxe-form-item title="机构类型" span="6">
          <vxe-select v-model="searchData.orgType" placeholder="请选择机构类型" style="width:80%;">
            <vxe-option
              v-for="item in orgTypeList"
              :key="item.dictPidVal"
              :label="item.dictDesc"
              :value="item.dictPidVal"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item title="名称" span="6">
          <vxe-input v-model="searchData.name" placeholder="请输入名称" clearable style="width:80%" />
        </vxe-form-item>
        <vxe-form-item title="状态" span="6">
          <vxe-select v-model="searchData.centStatus" placeholder="请选择状态" style="width:80%;">
            <vxe-option
              v-for="item in sumCenterList"
              :key="item.dictPidVal"
              :label="item.dictDesc"
              :value="item.dictPidVal"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item title="审核状态" span="6">
          <vxe-select v-model="searchData.auditStatus" placeholder="请选择审核状态" style="width:80%;">
            <vxe-option
              v-for="(item, index) in auditStatusArray"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item title="所属社区" span="6">
          <vxe-input
            v-model="searchData.community"
            placeholder="请输入所属社区"
            clearable
            style="width:80%;"
          />
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">查询</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
        <template v-slot:name="{ row }">
          <p class="link-a" @click="toDetail('detail', row)">{{ row.name }}</p>
        </template>
        <template v-slot:jiankong="{ row }">
          <p v-permission="'SumCenterListMonitor'" class="link-a" @click="openMoniter('sumCenter', row)"><img
            src="@/assets/images/monitor.png"
            alt=""
          >
          </p>
        </template>
        <template v-slot:operation="{ row }">
          <vxe-button v-permission="'AddSumCenter'" type="text" status="primary" @click="goPage('edit', row)">修改</vxe-button>
          <vxe-button v-permission="'SumCenterDeleteButton'" type="text" status="primary" @click="remove(row.id)">删除</vxe-button>
        </template>
      </vxe-grid>
      <vxe-pager
        :current-page.sync="tablePage.pageNum"
        :page-size.sync="tablePage.pageSize"
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult"
        :layouts="layouts"
        @page-change="pageChange"
      />
    </div>
    <el-dialog custom-class="moniter" title="监控" :visible.sync="moniterVisible" width="35%">
      <moniter :id="moniterId" ref="moniter" />
    </el-dialog>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations
} from 'vuex'
import api from '@/store/API/api'
import moniter from './components/moniter'
export default {
  components: {
    moniter
  },
  data() {
    return {
      sumCenterList: [],
      loading: false,
      seachStatus: false,
      moniterVisible: false,
      auditStatusArray: ['待审核', '正常', '拒绝'],
      moniterId: '',
      searchData: {},
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        pageNum: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 50,
          align: 'center'
        },
        {
          field: 'name',
          title: '名称',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
          slots: {
            default: 'name'
          }
        },
        {
          field: 'centStatusName',
          title: '状态',
          showOverflow: true,
          align: 'center',
          minWidth: '70'
        },
        {
          field: '',
          title: '监控',
          showOverflow: true,
          align: 'center',
          minWidth: '70',
          slots: {
            default: 'jiankong'
          }
        },
        {
          field: 'nature',
          title: '性质',
          showOverflow: true,
          align: 'center',
          minWidth: '70'
        },
        {
          field: 'belongCm',
          title: '所属社区',
          showOverflow: true,
          align: 'center',
          minWidth: '120'
        },
        {
          field: 'operCompName',
          title: '运营企业',
          showOverflow: true,
          align: 'center',
          minWidth: '120'
        },
        {
          field: 'auditStatus',
          title: '审核状态',
          showOverflow: true,
          align: 'center',
          minWidth: '140',
          formatter: data => {
            if (data.row.auditStatus in this.auditStatusArray) {
              let text = this.auditStatusArray[data.row.auditStatus]
              if (data.row.auditStatus === 2 && data.row.reason) {
                text += `：${data.row.reason}`
              }
              return text
            } else {
              return ''
            }
          }
        },
        {
          field: 'account',
          title: '登录账号',
          showOverflow: true,
          align: 'center',
          minWidth: '120'
        },
        {
          title: '操作',
          width: 200,
          showOverflow: true,
          slots: {
            default: 'operation'
          }
        }
      ],
      tableData: []
    }
  },
  computed: {
    ...mapState([
      'layouts'
    ]),
    ...mapGetters(['seqId'])
  },
  watch: {
    seqId() {
      // this.getLists()
    }
  },
  created() {
    this.initView()
    this.getDicList()
  },
  methods: {
    ...mapActions([
      'getSumCenterList',
      'deleteSumCenter',
      'getSysDictServe',
      'exportSumCenter'
    ]),

    initView() {
      this.getLists()
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    goPage(type, itemData) {
      this.$router.push({
        name: 'AddSumCenter',
        params: {
          type: type,
          id: type == 'add' ? 'add' : itemData.id
        },
        query: {
          item: type == 'add' ? 'add' : JSON.stringify(itemData)

        }
      })
    },
    getLists() {
      this.loading = true
      this.getSumCenterList({

        // param: {
        name: this.searchData.name,
        centStatus: this.searchData.centStatus,
        auditStatus: this.searchData.auditStatus,
        orgType: this.searchData.orgType,
        community: this.searchData.community,
        pageNum: this.tablePage.pageNum,
        // pageSize: this.tablePage.pageSize,
        pageSize: this.tablePage.pageSize,
        hasTenant: true

        // }
      }).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      // console.log(123);
      // console.log(item);
      if (item.type == 'size') {
        this.tablePage.pageNum = 1
      } else {
        this.tablePage.pageNum = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      // console.log(this.searchData);
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    },
    remove(id) {
      this.$XModal.confirm('您确定要删除该数据?').then(type => {
        if (type === 'confirm') {
          // console.log('执行删除操作');
          this.delete(id)
        }
      })
    },
    delete(id) {
      this.deleteSumCenter({
        id: id
      }).then(res => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success'
          })
          this.getLists()
        }
        this.loading = false
      })
    },
    getDicList() {
      this.getSysDictList('sunCenterStatus', 'sumCenterList')
      this.getSysDictList('org_type', 'orgTypeList')
    },
    // 获取数据字典
    getSysDictList(code, codeList) {
      const params = {
        typeCode: code
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    // 导出功能
    exportData() {
      let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
      url += api.exportSumCenter
      const myObj = {
        method: 'post',
        url,
        fileName: '日照中心',
        params: {
          pageNum: this.tablePage.pageNum,
          pageSize: this.tablePage.pageSize,
          // param: {
          name: this.searchData.name,
          centStatus: this.searchData.centStatus,
          community: this.searchData.community,
          orgType: this.searchData.orgType
          // }
        }
      }
      this.$tools.exportMethod(myObj)
    },
    toDetail(type, itemData) {
      this.$router.push({
        name: 'SumCenterDetail',
        params: {
          type: 'detail',
          id: itemData.id
        },
        query: {
          type: type,
          item: type == 'add' ? 'add' : JSON.stringify(itemData),
          cmOrEntId: type == 'add' ? 'add' : itemData.id

        }
      })
    },
    openMoniter(type, itemData) {
      this.moniterVisible = true
      this.moniterId = itemData.id
      this.$nextTick(() => {
        this.$refs.moniter.queryList()
      })
    },
    toMonitor(type, itemData) {
      this.$router.push({
        name: 'Moniter',
        query: {
          id: itemData.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
	overflow-y: auto;
}

.wrapper {
	.datetime {
		display: flex;
		align-items: center;

		span {
			margin: 0 3px;
		}
	}
}

.sign i {
	margin-right: 3px;
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}

::v-deep(.el-dialog__body) {
	padding-top: 10px;
}
</style>
